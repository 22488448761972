import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=1319a212&scoped=true&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&id=1319a212&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1319a212",
  null
  
)

/* custom blocks */
import block0 from "./Footer.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChangeurLangue: require('/usr/src/nuxt-app/components/ChangeurLangue.vue').default,Icone: require('/usr/src/nuxt-app/components/Icone.vue').default,LienBoutonIcone: require('/usr/src/nuxt-app/components/LienBoutonIcone.vue').default,Footer: require('/usr/src/nuxt-app/components/Footer.vue').default})
